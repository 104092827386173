<template>
  <v-dialog v-model="visible" persistent max-width="600">
    <v-card class="pa-5 rounded-lg" id="card-custom">
      <v-btn @click="visible = false" x-small icon class="float-right">
        <v-icon>close</v-icon>
      </v-btn>
      <v-card-text class="subtitle-2 px-0 font-weight-regular">
        Pegawai
      </v-card-text>
      <v-divider class="mb-4" />
      <v-text-field
        v-model="filter.search"
        placeholder="Cari"
        outlined
        dense
        hide-details
        prepend-inner-icon="search"
      ></v-text-field>
      <v-data-table
        class="mt-3"
        :headers="headers"
        :items="employees"
        :loading="loading"
        :options.sync="options"
        :server-items-length="pagination.totalItem"
        :footer-props="{
          'items-per-page-options': rowsPerPageItems
        }"
        loading-text="Loading... Please wait"
        item-key="no"
      >
        <template v-slot:[`item`]="{ item }">
          <tr>
            <td class="pt-2">
              {{ item.NIP }}
            </td>
            <td class="pt-2">
              {{ item.nama }}
            </td>
            <td>
              <v-btn
                small
                icon
                color="#FBB005"
                class="ml-2"
                :disabled="disabledItem(item)"
                @click="() => handlePick(item)"
              >
                <v-icon>check_circle_outline</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </v-dialog>
</template>
<script>
import PublicService from "@/services/resources/public.service";

export default {
  props: {
    lists: { type: Array, required: true },
    value: { type: String, required: true },
    singlePick: { type: Boolean, required: false }
  },
  data() {
    return {
      visible: false,
      loading: false,
      employees: [],
      filter: {
        search: ""
      },
      pagination: {
        page: 1,
        itemsPerPage: 10,
        totalItem: 0,
        pageCount: 0
      },
      options: {
        sortBy: ["nama"],
        sortDesc: [false]
      },
      rowsPerPageItems: [10, 20, 30, 40],
      headers: [
        {
          text: "NIP",
          value: "NIP",
          sortable: false,
          align: "center"
        },
        {
          text: "Nama",
          value: "nama",
          sortable: false,
          align: "center"
        },
        {
          text: "Aksi",
          value: "actions",
          sortable: false,
          align: "center"
        }
      ]
    };
  },
  computed: {
    paginationProperty() {
      return [this.filter.search].join();
    }
  },
  watch: {
    paginationProperty() {
      this.options.page = 1;
      this.fetchListDebounce(this.getListEmployee);
    },
    options: {
      handler() {
        this.fetchListDebounce(this.getListEmployee);
      },
      deep: true
    }
  },
  methods: {
    disabledItem(item) {
      let index = this.lists.findIndex(d => d[this.value] == item[this.value]);
      if (index < 0) return false;
      return true;
    },
    change(val) {
      this.visible = val;
    },
    handlePick(item) {
      this.$emit("handle-pick", item);
      if (this.singlePick) {
        this.visible = false;
      }
    },
    // Service
    async getListEmployee() {
      const { page, itemsPerPage, sortBy, sortDesc } = this.options;
      let orderBy = [];
      sortBy.map((d, i) => {
        let sort = sortDesc[i] ? "desc" : "asc";
        orderBy = [...orderBy, d, sort];
        return d;
      });
      try {
        this.loading = true;
        await PublicService.getUserSearchList({
          filter: {
            search: this.filter.search
          },
          orderBy,
          pageCurrent: page,
          dataPerPage: itemsPerPage
        })
          .then(response => {
            const { status, data } = response.data;
            const { list, pageTotal, countTotal } = data;
            if (status) {
              let employees = list;
              employees.map((data, index) => {
                data.no = itemsPerPage * (page - 1) + (index + 1);
                return data;
              });

              this.employees = employees;
              this.pagination.totalItem = parseInt(countTotal);
              this.pagination.pageCount = parseInt(pageTotal);
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loading = false));
      } catch (err) {
        console.error(err);
      }
    }
  },
  mounted() {
    this.getListEmployee();
  }
};
</script>
